<template>

	<div v-if="site" class="w-100 h-100 position-relative overflow-hidden">

		<div class="w-100 h-100 d-flex">

			<div class="d-flex flex-column w-100 h-100">

				<launch_nav :site="site" :preview="preview" />

				<div class="w-100 h-100 position-relative overflow-hidden d-flex">

					<div class="w-50 h-100 position-absolute top-0 start-0 p-4 d-flex align-items-start overflow-auto" style="z-index: 1">

						
						<form @submit.prevent="submit" class="w-100 mx-auto" style="max-width: 600px;">

							<div class="card">

								<div class="card-body p-4">

									<form @submit.prevent="submit">

										<div class="alert alert-info small">
											Add the relevant account ID's below to automatically add your analytics and tracking codes while keeping them GDPR compliant.
										</div>
										<div class="list-group mb-4">
											<label 
											v-for="( analytic, analytic_key ) in analytics" 
											:for="'include-' + analytic.key" 
											class="list-group-item p-2"
											:key="'analytic-' + analytic_key"
											>
												<span class="d-flex align-items-center">
													<span class="w-50 p-2">
														<p class="fw-bold mb-0">{{ analytic.name }}</p>
														<p v-if="analytic.description" class="form-text mb-0">{{ analytic.description }}</p>
													</span>

													<span class="w-50">
														<form_control
															type="text"
															:placeholder="analytic.placeholder ? analytic.placeholder : 'XXXXXX'"
															:name="analytic.key"
															:error="error"
															v-model="site.settings[analytic.key]"
															group_class="mb-0 analytics-id"
														/>
													</span>
												</span>

												
												
											</label>
										</div>

										<btn_submit 
											label="Update analytics" 
											icon="check"  
											:icon_after="true" 
											:loading="loading" 
										/>	

									</form>

								</div>
							</div>

						</form>
					</div>	

					<div class="w-50 h-100 position-absolute top-0 end-0 p-4 d-flex align-items-center overflow-auto border-start text-center" style="z-index: 1">
						
						<div class="w-100">

							<div class="w-100 text-start mx-auto" style="max-width: 600px;">
								<div class="card">
									<div v-if="tab && site.settings[tab]" class="card-body">
										<p class="fw-bold">This code will automatically be added to your site.</p>
										<div class="bg-light border rounded p-4">
											<code v-html="encodeHTML( tab )"></code>
										</div>
									</div>
									<div v-else class="card-body text-center">
										Add an ID to preview the relevant tracking code.
									</div>
								</div>
							</div>
						</div>

					</div>			

				</div>

			</div>

		</div>

	</div>

</template>

<script>

import { mapGetters } from 'vuex';
import launch_nav from '@/components/launch_nav'
import form_control from '@/components/form_control'
import btn_submit from '@/components/btn_submit'
import site_service from '@/services/site_service'

export default {
	name: 'site.analytics',

	props: {
		modelValue: [Object],
		workspace: [Object],
	},

	emits: [
		'update:modelValue',
	],

	components: {
		launch_nav,
		btn_submit,
		form_control
	},

	data() {
		return {
			loading: false,
			site: null,
			version: 0,
			wp_pages: [],
			tab: ''
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		analytics()
		{
			var analytics = [
				{
					key: 'analytics-google-analytics-id',
					name: 'Google Analytics ID',
					description: ''	
				},
				{
					key: 'analytics-google-ads-id',
					name: 'Google Ads ID',
					description: ''	
				},
				{
					key: 'analytics-meta-id',
					name: 'Meta / Facebook ID',
					description: ''	
				},
				{
					key: 'analytics-linkedin-id',
					name: 'LinkedIn ID',
					description: ''	
				},
				{
					key: 'analytics-hubspot-id',
					name: 'Hubspot URL',
					description: '',
					placeholder: '//js-XXX.hs-scripts.com/XXXXXX.js'	
				},
				{
					key: 'analytics-hotjar-id',
					name: 'HotJar ID',
					description: ''	
				},
				{
					key: 'analytics-microsoft-id',
					name: 'Microsoft Advertising ID',
					description: ''	
				},
				{
					key: 'analytics-clarity-id',
					name: 'Microsoft Clarity ID',
					description: ''	
				},
				{
					key: 'analytics-tag-manager-id',
					name: 'Tag Manager ID',
					description: 'When using Tag Manager, our GDPR functionality may not cover you.'	
				},
			]

			return analytics
		},

		preview()
		{
			var url = 'https://'

			if ( this.workspace ) {
				url += this.workspace.domain + '/storage/'
			}

			if ( this.site ) {
				url += this.site.slug
			}

			return url
		},
	},

	mounted()
	{
		this.site = JSON.parse( JSON.stringify( this.modelValue ) )

		var vm = this;
		var $ = window.$;
		$( document ).on( 'focus', '.analytics-id input', function() {
			var tab = $( this ).attr( 'name' );
			vm.tab = tab;
		} );
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async submit()
		{
			this.loading = true 

			this.reset_error()

			this.site.question = 'analytics'

			site_service.update( this.$route.params.workspace_id, this.$route.params.site_id, this.site ).then(( response ) => {

				this.site = JSON.parse( JSON.stringify( response.data ) )

				this.success = 'Updated successfully'

				this.$emit('update:modelValue', this.site)

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {

				this.loading = false

			})
		},

		encodeHTML( tab )
		{
			var id = ( this.site.settings[tab] ? this.site.settings[tab] : 'XXXXXX' );
			if ( tab == 'analytics-google-analytics-id' ) {
				return `&lt;script type=&quot;application/javascript&quot; src=&quot;https://www.googletagmanager.com/gtag/js?id=` + id + `&quot;&gt;&lt;/script&gt;
					<br><br>
					&lt;script  type=&quot;application/javascript&quot;&gt;
					<br>
					window.dataLayer=window.dataLayer||[];function gtag(){dataLayer.push(arguments)}gtag(&#39;js&#39;,new Date());gtag(&#39;config&#39;,&#39;` + id + `&#39;);	
					<br>	
					&lt;/script&gt;`;
			} else if ( tab == 'analytics-google-ads-id' ) {
				return `&lt;script type=&quot;application/javascript&quot; src=&quot;https://www.googletagmanager.com/gtag/js?id=` + id + `&quot;&gt;&lt;/script&gt;
						<br><br>
						&lt;script type=&quot;application/javascript&quot;&gt;
						<br>
						window.dataLayer=window.dataLayer||[];function gtag(){dataLayer.push(arguments)}gtag(&#39;js&#39;,new Date());gtag(&#39;config&#39;,&#39;` + id + `&#39;);
						<br>
						&lt;/script&gt;`
			} else if ( tab == 'analytics-meta-id' ) {
				return `&lt;script type=&quot;application/javascript&quot;&gt;
				<br>
				!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function()n.callMethod?.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version=&#39;2.0&#39;;n.queue=];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}window,document,&#39;script&#39;,&#39;https://connect.facebook.net/en_US/fbevents.js&#39;);fbq(&#39;init&#39;,&#39;` + id + `&#39;);fbq(&#39;track&#39;,&#39;PageView&#39;);
				<br>
				&lt;/script&gt;`;
			} else if ( tab == 'analytics-linkedin-id' ) {
				return `&lt;script type=&quot;application/javascript&quot;&gt;		
				<br>
				_linkedin_partner_id=&quot;` + id + `&quot;;window._linkedin_data_partner_ids=window._linkedin_data_partner_ids||[];window._linkedin_data_partner_ids.push(_linkedin_partner_id);(function(l){if(!l){window.lintrk=function(a,b){window.lintrk.q.push([a,b])};window.lintrk.q=[]}var s=document.getElementsByTagName(&quot;script&quot;)[0];var b=document.createElement(&quot;script&quot;);b.type=&quot;text/javascript&quot;;b.async=true;b.src=&quot;https://snap.licdn.com/li.lms-analytics/insight.min.js&quot;;s.parentNode.insertBefore(b,s)})(window.lintrk);		
				<br>
				&lt;/script&gt;`;
			} else if ( tab == 'analytics-hubspot-id' ) {
				return `&lt;script type=&quot;application/javascript&quot; src=&quot;` + id + `&quot;&gt;&lt;/script&gt;`;
			} else if ( tab == 'analytics-hotjar-id' ) {
				return `&lt;script type=&quot;application/javascript&quot;&gt;
				<br>br(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:` + id + `,hjsv:6};a=o.getElementsByTagName(&#39;head&#39;)[0];r=o.createElement(&#39;script&#39;);r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r)})(window,document,&#39;https://static.hotjar.com/c/hotjar-&#39;,&#39;.js?sv=&#39;);
				<br>
				&lt;/script&gt;`;
			} else if ( tab == 'analytics-microsoft-id' ) {
				return `&lt;script type=&quot;application/javascript&quot;&gt;
				<br> 
				(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:&quot;` + id + `&quot;};o.q=w[u],w[u]=new UET(o),w[u].push(&quot;pageLoad&quot;)},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&amp;&amp;s!==&quot;loaded&quot;&amp;&amp;s!==&quot;complete&quot;||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,&quot;script&quot;,&quot;//bat.bing.com/bat.js&quot;,&quot;uetq&quot;);
				<br>
				&lt;/script&gt;`;
			} else if ( tab == 'analytics-clarity-id' ) {
				return `&lt;script type=&quot;application/javascript&quot;&gt;
				<br> 
				(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src=&quot;https://www.clarity.ms/tag/&quot;+i+&quot;?ref=wordpress&quot;;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y)})(window,document,&quot;clarity&quot;,&quot;script&quot;,&quot;` + id + `&quot;);	
				<br> 
				&lt;/script&gt;`;
			} else if ( tab == 'analytics-tag-manager-id' ) {
				return `&lt;script type=&quot;application/javascript&quot;&gt;
				<br> 
				(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({&#39;gtm.start&#39;:new Date().getTime(),event:&#39;gtm.js&#39;});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!=&#39;dataLayer&#39;?&#39;&amp;l=&#39;+l:&#39;&#39;;j.async=true;j.src=&#39;https://www.googletagmanager.com/gtm.js?id=&#39;+i+dl;f.parentNode.insertBefore(j,f)})(window,document,&#39;script&#39;,&#39;dataLayer&#39;,&#39;` + id + `&#39;);
				<br> 
				&lt;/script&gt;`;
			}
		}
	}
}
</script>